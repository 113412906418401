/* Basic editor styles */
.ProseMirror{
    padding: 5px;
    border-top: 1px solid rgb(196, 196, 196);
    border-radius: 5px

  }

	.ProseMirror:focus {
    outline-color: #1876D1;
  }

  .menu-bar {
    // padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 45px;
      width: 49px;
      font-size: 26px;
      outline: none;
      border: none;
      color: rgb(90, 90, 90);
      background: none;
    }
  
    button.is-active{
      color: blue;
      background: rgb(230, 230, 230);
      padding: 2px 3px;
      border-radius: 2px;
    }
  }
  
  
  .tiptap {
      > * + * {
        margin-top: 0.75em;
      }

      font-family: 'Montserrat Variable';
    
      ul,
      ol {
        padding: 0 1rem;
      }
    
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 1.1;
      }
    
      code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
      }
    
      pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
    
        code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
        }
      }
    
      img {
        max-width: 100%;
        height: auto;
      }
    
      blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#9c9c9c, 0.1);
      }
    
      hr {
        border: none;
        border-top: 2px solid rgba(#9c9c9c, 0.1);
        margin: 2rem 0;
      }
    }
  
    .tiptap p.is-editor-empty:first-child::before {
      color: #adb5bd;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
      color: rgb(102, 102, 102);
    }

    .taskStyle {
      list-style-type: none; 
      div { display: inline-block; }
    }
		